<template>
  <v-main>
    <v-container fill-height fluid>
      <v-layout class="mx-auto mt-16 ma-6" justify-center>
        <v-flex>
          <v-card class="mx-auto mt-16" max-width="800" color="transparent" dark elevation="0">
            <v-row justify="center">
              <span class="pa-2">
                <v-icon color="red darken-2" justify-center size="200"> mdi-alert </v-icon>
                <h2 justify-center>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Login First! </h2>
              </span>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
</v-main>
</template>

<script>
export default {
  data () {
    return {
      card_text: 'Login First'
    }
  }
}
</script>
